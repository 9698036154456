import React from "react";
import './skill.css';

export default class Skill extends React.PureComponent {
    render() {
        return (<div className="card ">
                <p className="card-title">专业技能</p>
                <p className="card-content">
                    <span className="skill">Vue</span>
                    <span className="skill">React</span>
                    <span className="skill">JavaScript</span>
                    <span className="skill">ES6</span>
                    <span className="skill">Redux</span>
                    <span className="skill">Linux</span>
                    <span className="skill">CSS3</span>
                    <span className="skill">小程序</span>
                    <span className="skill">Webpack</span>
                    <span className="skill">Git</span>
                    <span className="skill">CI</span>
                    <span className="skill">Python</span>
                    <span className="skill">Docker</span>
                    <span className="skill">Restful</span>
                    <span className="skill">Node</span>
                    <span className="skill">Express</span>
                    <span className="skill">Websocket</span>
                    <span className="skill">HTTP/2</span>
                </p>
            </div>)
    }
}