import React from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all";
import "./App.css";
import InternShipPage from "./components/Intership";
import Skill from "./components/Skill";
import {Splide, SplideSlide} from '@splidejs/react-splide';
// Default theme
import '@splidejs/react-splide/css';

function App() {
    return (
        <div className="App">
            <Splide
                className="my-splide"
                options={{
                    direction: 'ttb',
                    height: '100dvh',
                    wheel: true,
                    releaseWheel: true,
                    arrows: false,
                    // pagination: false,
                    speed: '600',
                    waitForTransition: true,
                    perPage: 1,
                    perMove: 1
                }}>
                <SplideSlide>
                  <div style={{
                    lineHeight: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <div className="profile"></div>
                    <h2 style={{
                      fontSize: 40,
                      marginBottom: 16
                    }}>孙果</h2>
                    <p style={{
                      marginTop: 0,
                      marginBottom: 16
                    }}>前端工程师</p>
                    <p style={{
                      marginTop: 0,
                      opacity: 0.7,
                      fontSize: 16}}
                    >
                        华南理工大学 <span style={{fontSize: 14}}>2021年毕业</span>
                    </p>
                    <p style={{marginTop: 0, position: 'relative', fontSize: 'calc(14px + 1.5vmin)'}}>
                        <a style={{color: "white"}} href="https://github.com/GoJam11">
                            <i className="fab fa-github icon"></i>
                        </a>
                        <i className="fab fa-weixin icon weixin"></i>

                        <p style={{fontSize: 18, margin: 5}}>ILOVEOS</p>

                        <a href="mailto:iyilan@qq.com" style={{color: "white"}}>
                            <i className="fas fa-envelope icon"></i>
                        </a>
                    </p>
                  </div>
                </SplideSlide>

                <SplideSlide>
                    <InternShipPage></InternShipPage>
                </SplideSlide>

                <SplideSlide>
                    <Skill/>
                </SplideSlide>
            </Splide>
        </div>
    );
}

export default App;
